import '../TextStyle.css';

export function Part5MobileComponent(): JSX.Element {
    return (
        <div className='part-container' style={{
            color: '#0A84FF',
            backgroundColor: 'white', height: 650
        }}>
            <div style={{
                display: 'flex', flexDirection: 'column',
                width: '100%', overflow: 'hidden'
            }}>
                <h2 className='margin-inline' style={{
                    zIndex: 10,
                }}>
                    {"Удобный вход\n для студентов ВШЭ"}
                </h2>
                <div className='default-sf-text margin-inline' style={{
                    zIndex: 10,
                }}>
                    Интеграция с ЕЛК НИУ ВШЭ позволит зайти в приложение <strong>в один клик</strong>
                </div>
                <div style={{ height: '100%', width: '100%', maxWidth: 1500, display: 'flex' }}>
                    <div style={{
                        display: 'flex', alignItems: "center", justifyContent: 'flex-end',
                        height: '100%', width: '100%',
                        zIndex: 5, marginTop: 20
                    }} >
                        <img style={{ maxWidth: '90%' }} src="/build/img/elk.png" />
                    </div>

                </div>
                <div className='absolute' >
                    <div style={{
                        display: 'flex', alignItems: "flex-start", justifyContent: 'center',
                        height: '100%', width: '100%', 
                        position: 'absolute', overflow: 'hidden'
                    }} >
                        <img style={{}} src="/build/img/lines_mobile_5_2.svg" />
                    </div>
                </div>
            </div>
        </div>
    )
}