import './DownloadButton.css';

export function DownloadButtonComponent(): JSX.Element {
    return (
        <a
            href='https://voronka-events.ru/about?loc=landing&at=link&aid=landing_redirect&redirect=store'
            target='_blank'
            className="download-btn">
            СКАЧАТЬ
        </a>
    )
}