export function ContactsTextComponent(props: { desktop: boolean }) {
    return (
        <div className='contacts-text'>
            <h2 style={{
                marginBottom: 5, lineHeight: 'normal', fontSize: props.desktop ? undefined : 42
            }}>
                Контакты
            </h2>
            <div style={{ fontFamily: 'Onest', fontSize: props.desktop ? 24 : 18, fontWeight: 500, whiteSpace: 'pre-wrap' }}>
                {'Для рекламодателей\nи организаторов:'}
            </div>
            <div style={{
                display: 'flex', flexDirection: props.desktop ? 'row' : 'column', rowGap: '10px',
                columnGap: '30px', marginTop: 10, zIndex: 30, fontSize: props.desktop ? 24 : 18
            }}>
                <div>
                    <div style={{ fontFamily: 'Onest', fontWeight: 600 }}>
                        Email
                    </div>
                    <a href="mailto:voronka_technologies@mail.ru" style={{
                        fontFamily: 'SFProDisplay', color: 'black', textDecoration: 'none'
                    }}>
                        voronka_technologies@mail.ru
                    </a>
                </div>
                <div>
                    <div style={{ fontFamily: 'Onest', fontWeight: 600 }}>
                        Telegram-support
                    </div>
                    <a href="https://t.me/voronka_app_bot" target="_blank" style={{
                        fontFamily: 'SFProDisplay', color: 'black', textDecoration: 'none'
                    }}>
                        @voronka_app_bot
                    </a>
                </div>
            </div>
        </div>
    )
}