export function FooterComponent(): JSX.Element {
    return (
        <div className="container-flex" style={{
            minWidth: '100%', marginTop: 'auto', zIndex: 25,
            display: 'flex', justifyContent: 'start'
        }}>
            <div style={{
                margin: '10px 20px 10px 20px',
                display: 'inline-flex', flexWrap: 'wrap',
                rowGap: '8px', columnGap: '20px', fontSize: 16, zIndex: 25, 
            }}>
                <a href="https://voronka-events.ru/document/privacy_policy" target="_blank">
                    Политика конфиденциальности
                </a>
                <a href="https://voronka-events.ru/document/terms_of_use_application" target="_blank">
                    Пользовательское соглашение
                </a>
            </div>
        </div>
    )
}