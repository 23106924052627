import '../TextStyle.css';
import './Part2.css'

export function Part2Component(props: { desktop: boolean }): JSX.Element {
    return (
        <div className='part-container' style={{
            color: '#0A84FF', backgroundColor: 'white',
        }}>
            <div style={{
                display: 'flex', flexDirection: 'column', maxWidth: 1600, width: '100%',
            }}>
                <h2 className='margin-inline' >
                    {"Выбирай,\nчто нравится!"}
                </h2>
                <div className='default-sf-text margin-inline tags-text' style={{
                    zIndex: 10,
                }}>
                    Отмечай <strong>свои интересы</strong> и получай персональные <strong>рекомендации</strong> мероприятий
                </div>

                <div className='tags-image-container' >
                    <img className='tags-image' src={props.desktop ? "/build/img/tags.svg" : "/build/img/tags_mobile2.svg"} />
                </div>
            </div>

        </div>
    )
}