import '../TextStyle.css';

export function Part3Component(): JSX.Element {
    return (
        <div className="part-container" style={{
            backgroundColor: 'white',
        }}>
            <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                width: '100%', maxWidth: 1600, columnGap: '30px',
            }}>
                <div style={{
                    display: 'flex', alignItems: 'center',
                    width: '50%',
                }} >
                    <img style={{
                        width: '100%', height: '100%',
                        objectFit: 'cover', objectPosition: 'right'
                    }} src="/build/img/pic_3.png" />
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: 'column', paddingLeft: 20,
                    width: '50%', backgroundColor: '#1F1F1F', justifyContent: 'flex-end',
                    overflow: 'hidden'
                }}>
                    <img src="/build/img/lines.svg" style={{ width: '100%', }} />
                    <div style={{ marginBlock: 'auto'}}>
                        <h2 style={{
                            marginInline: 20,
                        }}>
                            Узнавай первым!
                        </h2>
                        <div className='default-sf-text' style={{
                            maxWidth: 720, marginInline: 20, marginBottom: 30,
                            fontFamily: 'SFProDisplay',
                        }}>
                            <strong>Лучшие организаторы</strong> создали анонсы мероприятий, чтобы ты ничего не пропустил и выбрал ивент <strong>по своим интересам</strong>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}