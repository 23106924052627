import { ContactsCardComponent } from "./ContactsCardComponent";
import { DownloadButtonComponent } from "../DownloadButtonComponent";
import './TGButton.css'

export function Part8MobileComponent(): JSX.Element {
    return (
        <div className='part-container' style={{
            minHeight: 630, overflow: 'hidden',
            backgroundImage: 'url(../build/img/background_mobile_8.png)',
            backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
        }}>
            <div style={{
                position: 'absolute', top: 0, overflow: 'hidden',
                maxWidth: 1600, width: '100%', height: '100%',
                display: 'flex', flexDirection: 'column', zIndex: 40,
                fontFamily: 'Onest', fontWeight: '500', fontSize: 32
            }}>
                <div className="margin-inline" style={{
                    display: 'flex', alignItems: 'center', flexDirection: 'column',
                    marginTop: 15, gap: 10
                }}>
                    <div style={{
                        display: "flex", justifyContent: 'space-between', flexDirection: 'row',
                        alignItems: 'center', width: '100%'
                    }}>
                        <div className="default-onest-text" style={{ alignSelf: 'center' }}>Воронка</div>
                        <DownloadButtonComponent />
                    </div>
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'row', position: 'relative',
                    marginTop: 20
                }}>
                    <img style={{
                        zIndex: 20
                    }} src="/build/img/lines_mobile_8.png" />
                    <a href="https://t.me/voronka_for_everyone" target="_blank" style={{
                        display: 'flex', justifyContent: 'flex-end',
                        position: 'absolute', right: 20
                    }}>
                        <img className="tg-btn" style={{ height: 60, zIndex: 30 }} src="/build/img/tg2.svg" />
                    </a>
                </div>
                <div style={{
                    display: 'flex', justifyContent: 'center',
                    width: '100%', height: 370,
                    marginBlock: 'auto',
                }} >
                    <ContactsCardComponent desktop={false} />
                </div>
            </div>
        </div>
    )
}