import { DownloadButtonComponent } from "../DownloadButtonComponent";
import { ListItemComponent } from "./ListItemComponent";
import '../TextStyle.css';
import './Part1.css'

export function Part1MobileComponent(): JSX.Element {
    return (
        <div className="part-container container" style={{
            minHeight: 740, overflow: 'hidden',
            backgroundPosition: 'left center'
        }}>
            <div className="main-content" style={{
                overflow: 'hidden'
            }}>
                <div className="margin-inline top" style={{
                    height: 110, paddingTop: 30
                }}>
                    <div className="default-onest-text">Воронка</div>
                    <DownloadButtonComponent />
                </div>
                <h1>
                    Новый формат студлайфа
                </h1>
                <div className="li-container" >
                    <ListItemComponent text="найдет ивент по интересам" />
                    <ListItemComponent text="подскажет кто из знакомых идет на него" />
                    <ListItemComponent text="подберет отличную компанию" />
                </div>
                <div style={{
                    display: 'flex', alignItems: "center", justifyContent: 'center',
                    height: '100%', width: '100%',
                    zIndex: 5
                }} >
                    <img style={{ maxWidth: '90%' }} src="/build/img/announcements2.png" />
                </div>
            </div>
            <div className="absolute" style={{ maxWidth: 1600 }}>
                <div className="absolute" style={{
                    display: 'flex', alignItems: "flex-start", justifyContent: 'center',
                    overflow: 'hidden'
                }} >
                    <img style={{ alignSelf: 'end' }} src="/build/img/lines_mobile_1.svg" />
                </div>
            </div>
        </div>
    )
}