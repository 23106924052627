import '../TextStyle.css';
import { DownloadButtonComponent } from "../DownloadButtonComponent";

export function Part4Component(): JSX.Element {
    return (
        <div className="part-container" style={{
            backgroundColor: '#1F1F1F', overflow: 'hidden',
            backgroundImage: 'url(../build/img/background_4_1.png)',
            backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
        }}>
            <div style={{
                position: 'absolute', top: 0, overflow: 'hidden',
                height: '100%', width: '100%', maxWidth: 1600,
                display: 'flex', flexDirection: 'column',
                zIndex: 20,
            }}>
                <div className='margin-inline' style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                }}>
                    <div style={{ marginTop: 0 }}>
                        <h2 style={{
                            maxWidth: 1000, marginBottom: 30,
                        }}>
                            {"Ты ничего\nне пропустишь"}
                        </h2>
                        <div className='default-sf-text' style={{
                            maxWidth: 550, marginBottom: 30,
                        }}>
                            Все регистрации, понравившиеся и архивные ивенты <strong>под рукой</strong> во встроенном календаре
                        </div>
                    </div>
                    <div style={{ alignSelf: 'flex-start', display: 'flex', marginTop: 30 }}>
                        <DownloadButtonComponent />
                    </div>
                </div>
                <div className='margin-inline' style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: "flex-start", justifyContent: 'center',
                    height: '100%', marginTop: -56
                }} >
                    <img style={{ width: '100%', }} src="/build/img/cards_full3.png" />
                </div>
            </div>
            <div className='absolute' >
                <div style={{
                    display: 'flex', flexDirection: 'column', overflow: 'hidden',
                    alignItems: "flex-end", justifyContent: 'flex-start',
                    height: '100%', paddingRight: 60,
                }} >
                    <img style={{
                        zIndex: 80, maxWidth: '16%', marginTop: 120,
                    }} src="/build/img/arrow2.svg" />
                </div>
            </div>

        </div>
    )
}