import './ListItem.css'

export function ListItemComponent(props: { text: string, paddingRight?: number }) {
    return (
        <span className='list-item' style={{
            paddingRight: props.paddingRight ? props.paddingRight : undefined,
        }}>
            <img className='list-star' src="/build/img/star.svg" />
            <div className='default-onest-text'>{props.text}</div>
        </span>
    )
}