import React from 'react';
import './ImageSlider.css';
import '../TextStyle.css';

export function Part7Component(): JSX.Element {
    return (
        <div className='backgr part-container' style={{
            alignItems: 'flex-start',
            backgroundColor: '#1F1F1F',
            backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
        }}>
            <div className='frame-container' >
                <h2 className='margin-inline' style={{
                    textAlign: 'center', zIndex: 10,
                }}>
                    {"Мы сотрудничаем с"}
                </h2>
                <img src="/build/img/hse_logo.png" style={{ maxWidth: 500, width: '80%', }} />

                <div style={{ maxWidth: '100%', }}>
                    <div className="image-slider-container">
                        <div className="image-slider">
                            <img src="/build/img/pics1.png" alt="Image 1" />
                            <img src="/build/img/pics2.png" alt="Image 2" />
                        </div>
                        <div className="image-slider">
                            <img src="/build/img/pics1.png" alt="Image 1" />
                            <img src="/build/img/pics2.png" alt="Image 2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}