import React, { useEffect, useState } from 'react';
import './App.css';
import { Part1Component } from './components/Part1/Part1';
import { Part2Component } from './components/Part2/Part2';
import { Part3Component } from './components/Part3/Part3';
import { Part4Component } from './components/Part4/Part4';
import { Part5Component } from './components/Part5/Part5';
import { Part6Component } from './components/Part6/Part6';
import { Part7Component } from './components/Part7/Part7';
import { Part8Component } from './components/Part8/Part8';
import { FooterComponent } from './components/FooterComponents';
import { Part1MobileComponent } from './components/Part1/Part1_Mobile';
import { Part3MobileComponent } from './components/Part3/Part3_Mobile';
import { Part4MobileComponent } from './components/Part4/Part4_Mobile';
import { Part5MobileComponent } from './components/Part5/Part5_Mobile';
import { Part6MobileComponent } from './components/Part6/Part6_Mobile';
import { Part8MobileComponent } from './components/Part8/Part8_Mobile';
import { BrowserRouter } from "react-router-dom";

function App() {
  const [desktopView, setDesktopView] = useState<boolean>()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setDesktopView(true);
      } else {
        setDesktopView(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    console.log(desktopView)

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  return (
    <BrowserRouter basename='/about'>
      <div>
        {desktopView &&
          <div>
            <Part1Component />
            <Part2Component desktop={desktopView} />
            <Part3Component />
            <Part4Component />
            <Part5Component />
            <Part7Component />
            <Part8Component />
          </div>}

        {!desktopView && desktopView != undefined &&
          <div>
            <Part1MobileComponent />
            <Part2Component desktop={desktopView} />
            <Part3MobileComponent />
            <Part4MobileComponent />
            <Part5MobileComponent />
            <Part7Component />
            <Part8MobileComponent />
          </div>}

        <FooterComponent />
      </div>
    </BrowserRouter>
  );
}

export default App;
