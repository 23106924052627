import '../TextStyle.css';
import { DownloadButtonComponent } from "../DownloadButtonComponent";

export function Part4MobileComponent(): JSX.Element {
    return (
        <div className='part-container' style={{
            backgroundColor: '#1F1F1F', overflow: 'hidden',
            backgroundImage: 'url(../build/img/background_4_1.png)',
            backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'left center'
        }}>
            <div style={{
                height: '100%',width: '100%', maxWidth: 1600,
                display: 'flex', flexDirection: 'column',
                zIndex: 20
            }}>
                <div style={{ marginTop: 0 }}>
                    <h2 className='margin-inline' >
                        {"Ты ничего\nне пропустишь"}
                    </h2>
                    <div className='default-sf-text margin-inline' style={{
                        marginBottom: 30,
                    }}>
                        Все регистрации, понравившиеся и архивные ивенты <strong>под рукой</strong> во встроенном календаре
                    </div>
                </div>
                <div style={{
                    position: 'relative',
                    display: 'flex', justifyContent: 'center', flexDirection: 'column',
                    alignContent: 'center', alignItems: 'center',
                    height: 96,
                }}>
                    <div style={{ marginInline: 40, zIndex: 40 }}><DownloadButtonComponent /></div>

                    <div style={{
                        position: 'absolute',
                        display: 'flex', flexDirection: 'column',
                        alignItems: "center", justifyContent: 'flex-start',
                        height: '100%', right: '50%', zIndex: 0
                    }} >
                        <img style={{
                            rotate: '22deg', scale: '-1 1', height: 300,
                            marginTop: '26%', marginRight: '43%'
                        }} src="/build/img/arrow2.svg" />
                    </div>

                </div>
                <div style={{
                    marginTop: 30, marginBottom: -50, alignSelf: 'center',
                    display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'
                }}>
                    <img style={{ width: 1000, marginRight: -100, maxWidth: '260%', objectFit: 'contain', zIndex: 5 }}
                        src="/build/img/cards_full3.png" />
                </div>
            </div>
        </div>
    )
}