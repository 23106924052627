import '../TextStyle.css';

export function Part3MobileComponent(): JSX.Element {
    return (
        <div className='part-container' style={{
            backgroundColor: '#1F1F1F'
        }}>
            <div style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                width: '100%', maxWidth: 1600, columnGap: '30px',
            }}>
                <div style={{
                    display: "flex", flexDirection: 'column', backgroundColor: '#1F1F1F'
                }}>
                    <h2 style={{
                        marginInline: 20,
                    }}>
                        Узнавай первым!
                    </h2>
                    <div className='default-sf-text' style={{
                        marginInline: 20, marginBottom: 30,
                    }}>
                        <strong>Лучшие организаторы</strong> создали анонсы мероприятий, чтобы ты ничего не пропустил и выбрал ивент <strong>по своим интересам</strong>
                    </div>
                </div>
                <div style={{
                    display: 'flex', alignItems: 'center', height: '100%'
                }} >
                    <img style={{
                        width: '100%', height: '100%', maxHeight: 740,
                        objectFit: 'cover', objectPosition: 'top'
                    }} src="/build/img/pic_3.png" />
                </div>
            </div>
        </div>
    )
}