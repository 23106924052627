import { DownloadButtonComponent } from "../DownloadButtonComponent";
import { ListItemComponent } from "./ListItemComponent";
import '../TextStyle.css';
import './Part1.css'

export function Part1Component(): JSX.Element {
    return (
        <div className="part-container container">
            <div className="main-content">
                <div className="margin-inline top" style={{ height: 120, }}>
                    <div className="default-onest-text">Воронка</div>
                    <DownloadButtonComponent />
                </div>
                <h1>
                    Новый формат студлайфа
                </h1>
                <div className="li-container" style={{ display: "flex", flexDirection: "column", maxWidth: '60%' }}>
                    <ListItemComponent text="найдет ивент по интересам" paddingRight={0} />
                    <ListItemComponent text="подскажет кто из знакомых идет на него" paddingRight={20} />
                    <ListItemComponent text="подберет отличную компанию" paddingRight={40} />
                </div>
            </div>
            <div className="absolute" style={{ maxWidth: 1500, overflow: 'hidden' }}>
                <div className="absolute" style={{
                    display: 'flex', alignItems: "center", justifyContent: 'flex-end',
                    right: 30, top: 200, 
                    zIndex: 5
                }} >
                    <img style={{ maxWidth: '46%', }} src="/build/img/announcements2.png" />
                </div>
            </div>
            <div className="absolute" style={{ maxWidth: 1600 }}>
                <div className="absolute" style={{
                    display: 'flex', alignItems: "flex-start", justifyContent: 'flex-end',
                }} >
                    <img style={{ height: '115%' }} src="/build/img/lines_1.svg" />
                </div>
            </div>
        </div>
    )
}