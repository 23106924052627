import '../TextStyle.css';
import './ContactsCard.css'
import { ContactsTextComponent } from './ContactsTextComponent';

export function ContactsCardComponent(props: { desktop: boolean }) {
    return (
        <div className='contacts-container'>
            <div className='card-background'>
                <ContactsTextComponent desktop={props.desktop} />
            </div>
        </div>
    )
}