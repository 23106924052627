import { ContactsCardComponent } from "./ContactsCardComponent";
import { DownloadButtonComponent } from "../DownloadButtonComponent";
import './TGButton.css'

export function Part8Component(): JSX.Element {
    return (
        <div className="part-container" style={{
            minHeight: 600,
            backgroundImage: 'url(../build/img/background_81.png)',
            backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
        }}>
            <div style={{
                position: 'absolute', top: 0,
                maxWidth: 1600, width: '100%',
                display: 'flex', flexDirection: 'column', zIndex: 40,
                fontFamily: 'Onest', fontWeight: '500', fontSize: 32
            }}>
                <div style={{
                    display: 'flex', justifyContent: 'start', alignItems: 'center',
                    paddingInline: 40, marginTop: 15,
                }}>
                    <div style={{ alignSelf: 'center', marginRight: 40 }}>Воронка</div>
                    <div style={{
                        display: "flex", flexDirection: 'row', alignItems: 'center', gap: 40
                    }}>
                        <a className="tg-btn" href="https://t.me/voronka_for_everyone" target="_blank" style={{ display: 'flex' }}>
                            <img style={{ height: 90 }} src="/build/img/tg2.svg" />
                        </a>
                        <DownloadButtonComponent />
                    </div>
                    <div style={{ width: 130 }}></div>
                </div>

            </div>
            <div className="absolute" style={{ overflow: 'hidden' }}>
                <div style={{
                    display: 'flex', alignItems: "flex-start", justifyContent: 'flex-end',
                    height: '100%', width: '100%',
                    position: 'absolute',
                }} >
                    <ContactsCardComponent desktop={true} />

                </div>
            </div>
            <div className="absolute" style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                paddingTop: 60, zIndex: 20
            }}>
                <img style={{
                    width: '100%', zIndex: 20
                }} src="/build/img/lines_8.svg" />
            </div>
        </div>
    )
}