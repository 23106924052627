import '../TextStyle.css';

export function Part5Component(): JSX.Element {
    return (
        <div className="part-container" style={{
            color: '#0A84FF',
            backgroundColor: 'white',
        }}>
            <div style={{
                display: 'flex', flexDirection: 'column',
                width: '100%', maxWidth: 1600,
            }}>
                <h2 className='margin-inline' style={{
                    marginBottom: 30, maxWidth: 1100, zIndex: 10,
                }}>
                    {"Удобный вход\n для студентов ВШЭ"}
                </h2>
                <div className='default-sf-text margin-inline' style={{
                    maxWidth: 440, paddingRight: 270, zIndex: 10,
                }}>
                    Интеграция с ЕЛК НИУ ВШЭ позволит зайти в приложение <strong>в один клик</strong>
                </div>
                <div style={{
                    display: 'flex', justifyContent: 'center',
                    width: '100%', marginTop: 'auto',
                    backgroundColor: 'white'
                }} >
                    <img style={{ width: '100%', maxWidth: 1600, backgroundColor: 'white' }} src="/build/img/lines_5.svg" />
                </div>
            </div>
            <div className='absolute' style={{
                display: 'flex', justifyContent: 'end',
            }} >
                <img style={{
                    maxWidth: '40%', maxHeight: 800, objectFit: 'scale-down',
                    marginBlock: 10
                }} src="/build/img/elk.png" />
            </div>
        </div>
    )
}